// @flow

import { useRef, useCallback, useEffect, useState } from "react";

type ReturnVals<T> = {
  data: T | null,
  blob: ?Blob,
  fetched: boolean,
  forceUpdate: () => Promise<void>,
  setData: (T | null | Blob) => void,
};

export default function useFetch<T>(
  input: RequestInfo | null,
  init: ?RequestOptions
): ReturnVals<T> {
  const [fetched, setFetched] = useState<boolean>(false);
  const [response, setResponse] = useState<?Response>(null);
  const [data, setData] = useState<T | null | Blob>(null);
  const abortController = useRef<AbortController | null>(null);

  const sendRequest = useCallback(async () => {
    if (!input) {
      return;
    }

    abortController.current = new AbortController();
    setFetched(false);
    const signal = abortController.current
      ? abortController.current.signal
      : null;
    const response = await fetch(input, { ...init, signal });
    setResponse(response);
    const contentType = response.headers.get("content-type");
    const data =
      contentType && contentType.startsWith("application/json")
        ? await (response.json(): Promise<T>)
        : await response.blob();
    setData(data);
    setFetched(true);
  }, [abortController, init, input]);

  useEffect(() => {
    if (input) {
      sendRequest();
    }

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
  }, [input, init, abortController, sendRequest]);

  const forceUpdate = useCallback(async () => {
    if (input) {
      await sendRequest();
    }
  }, [input, sendRequest]);

  return {
    response,
    data: data instanceof Blob ? null : data,
    blob: data instanceof Blob ? data : null,
    fetched,
    setData,
    forceUpdate,
  };
}
